import { Box, Typography } from "@mui/material";
import { onMessage } from "firebase/messaging";
import { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  messaging,
  registerServiceWorker,
  requestPermissions,
} from "../../../firebase";

const useFirebaseNotifications = () => {
  // const { i18n } = useTranslation();
  // const setHasNotification = notificationStore(
  //   (state) => state.setHasNotification
  // );
  // const setCount = unreadNotifications((state) => state.setCount);
  // const count = unreadNotifications((state) => state.count);

  useEffect(() => {
    requestPermissions();
    registerServiceWorker();

    onMessage(messaging, (payload) => {
      // if (payload.data.body) {
      //   setHasNotification(true);
      //   setCount(count + 1);
      // }
      toast.success(
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "300px",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            {payload.notification?.title}
          </Typography>
          <Typography>{payload.notification?.body}</Typography>
          {payload?.notification?.image ? (
            <img
              src={payload?.notification?.image}
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          ) : null}
        </Box>,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default useFirebaseNotifications;
