import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { Box } from "@mui/material";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { HttpResponseInterceptor } from "interceptor/http-response.interceptor";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { _AuthApi } from "services/auth/auth.service";
import { _NotificationsApi } from "services/notifications/notifications.service";
import "./App.css";
import AppRouting from "./AppRouting";

function App() {
  const navigate = useNavigate();
  const sookler_token = _AuthApi.getToken();

  const getDeviceId = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    return result.visitorId;
  };

  useEffect(() => {
    HttpRequestInterceptor();
    HttpResponseInterceptor(navigate);

    const init = async () => {
      try {
        const deviceId = await getDeviceId();

        const formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("token", sookler_token);
        formData.append("device_type", "web");
        formData.append("device_id", deviceId);

        await _NotificationsApi.updateFcmToken({ formData });
      } catch (error) {}
    };
    if (sookler_token) {
      init();
    }

    const handleVisibilityChange = () => {
      document.title = document.hidden ? "Come Back :(" : "Sookler";
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sookler_token]);

  return (
    <Box color="background.main">
      <AppRouting />
    </Box>
  );
}

export default App;
