import { _axios } from "interceptor/http-config";
import Cookies from "js-cookie";

export const _AuthApi = {
  login: async (data) => {
    return _axios.post("/login", data).then((res) => {
      const token = res.data?.data?.access_token;
      const isAdmin = res.data?.data?.is_admin;
      const subdomain = res.data?.data?.subdomain;

      if (token) {
        _AuthApi.storeToken(token, 1);
      }
      if (isAdmin !== undefined) {
        _AuthApi.storeIsAdmin(isAdmin, 1);
      }
      if (subdomain) {
        _AuthApi.storeSubDomain(subdomain, 1);
      }
    });
  },

  storeToken: (token, hours) => {
    Cookies.set("sookler_token", token, { expires: hours, secure: true });
  },
  storeIsAdmin: (is_admin, hours) => {
    Cookies.set("is_admin", is_admin, { expires: hours });
  },
  storeSubDomain: (subdomain, hours) => {
    Cookies.set("subdomain", subdomain, { expires: hours });
  },

  getToken: () => Cookies.get("sookler_token"),
  getIsAdmin: () => Cookies.get("is_admin"),
  getSubDomain: () => Cookies.get("subdomain"),

  destroyToken: () => {
    Cookies.remove("sookler_token");
    Cookies.remove("is_admin");
    Cookies.remove("subdomain");
    window.location.reload();
  },

  sendCode: async (data) => {
    return _axios.post("/send-code", data);
  },
  verifyCode: async (data, config) => {
    return _axios.post("/check-code", data, {
      headers: config.headers,
    });
  },
  resetPassword: async (data) => {
    return _axios.post("/reset-password", data);
  },
  resendCode: async (data) => {
    return _axios.post("/resend_code", data);
  },
  updateProfile: async (formData) => {
    return _axios.post("/admins/profile", formData);
  },
};
